// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cart-js": () => import("./../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-content-js": () => import("./../src/pages/content.js" /* webpackChunkName: "component---src-pages-content-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-now-js": () => import("./../src/pages/now.js" /* webpackChunkName: "component---src-pages-now-js" */),
  "component---src-pages-page-js": () => import("./../src/pages/page.js" /* webpackChunkName: "component---src-pages-page-js" */),
  "component---src-pages-purchase-js": () => import("./../src/pages/purchase.js" /* webpackChunkName: "component---src-pages-purchase-js" */)
}

